/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react'
import { Button, Flex, jsx } from 'theme-ui'
import CancelIcon from '../../icons/cancel-icon.svg'
import { css, Global } from '@emotion/react'
import BigChevronLeft from '../../icons/chevron-left-big.svg'

export const pinBody = css`
  body {
    overflow-y: hidden;
    padding-right: 7.5px;
  }
`

export const ModalButton = ({
  modalWidth,
  modalHeight,
  buttonText,
  buttonIcon,
  buttonVariant = 'primary',
  modalHeader,
  modalHeaderStyles,
  buttonDisabled,
  onClose,
  buttonStyles,
  modalStyles,
  modalContentStyles,
  modalOverlayStyles,
  modalWrapperStyles,
  modalCloseButtonOpacity = true,
  customModalButtonComponent,
  goBackFunction,
  renderModalContent = () => {},
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  return (
    <Flex sx={{ ...modalWrapperStyles }}>
      {customModalButtonComponent ? (
        customModalButtonComponent({ isModalOpen, handleToggleModal })
      ) : (
        <Button
          onClick={() => handleToggleModal()}
          disabled={buttonDisabled}
          variant={buttonVariant}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '5px',
            width: '100%',
            height: '100%',
            ...buttonStyles,
          }}
        >
          {buttonIcon && buttonIcon}
          {buttonText}
        </Button>
      )}
      {isModalOpen && (
        <Modal
          toggleModal={handleToggleModal}
          modalClose={handleModalClose}
          isModalOpen={isModalOpen}
          onClose={onClose}
          modalWidth={modalWidth}
          modalHeight={modalHeight}
          modalHeader={modalHeader}
          modalHeaderStyles={modalHeaderStyles}
          modalStyles={modalStyles}
          modalContentStyles={modalContentStyles}
          modalOverlayStyles={modalOverlayStyles}
          closeButtonOpacity={modalCloseButtonOpacity}
          goBackFunction={goBackFunction}
        >
          {renderModalContent({ isModalOpen, handleToggleModal })}
        </Modal>
      )}
    </Flex>
  )
}

export function Modal(props) {
  const {
    isModalOpen,
    toggleModal,
    modalClose,
    modalWidth,
    modalHeight,
    modalHeader,
    modalHeaderStyles,
    onClose,
    modalStyles,
    modalContentStyles,
    modalOverlayStyles,
    closeButtonOpacity,
    goBackFunction,
  } = props
  const contentRef = useRef()

  useEffect(() => {
    const close = (e) => {
      if (e.key === 'Escape') {
        if (onClose) {
          onClose()
        }
        modalClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])
  return (
    <div
      sx={{
        position: 'fixed',
        zIndex: '1000000000',
        top: '0',
        left: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.47)',
        backdropFilter: 'blur(2px)',
        overflowY: 'auto',
        width: '100%',
        display: isModalOpen ? 'flex' : 'none',
        justifyContent: 'center',
        paddingTop: [10, null, null, '40px'],
        alignItems: 'flex-start',
        ...modalOverlayStyles,
      }}
      onClick={(event) => {
        if (!contentRef.current.contains(event.target)) {
          toggleModal()
          onClose && onClose()
        }
      }}
    >
      <Global styles={pinBody} />
      <div
        ref={contentRef}
        sx={{
          width: '90%',
          maxWidth: modalWidth || '1150px',
          borderRadius: '10px',
          position: 'relative',
          backgroundColor: 'lightText',
          boxShadow: '7px 7px 7px #717171',
          margin: '1% 0',
          overflowY: 'auto',
          ...modalStyles,
        }}
      >
        <button
          onClick={() => {
            modalClose()
            onClose && onClose()
          }}
          sx={{
            backgroundColor: closeButtonOpacity ? 'white' : 'transparent',
            opacity: closeButtonOpacity ? '0.9' : '1',
            borderRadius: '100%',
            padding: '2%',
            width: '35px',
            height: '35px',
            border: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '15px',
            right: '15px',
            zIndex: 999,
          }}
        >
          <img src={CancelIcon} />
        </button>
        {goBackFunction && (
          <button
            onClick={() => goBackFunction()}
            sx={{
              backgroundColor: closeButtonOpacity ? 'white' : 'transparent',
              opacity: closeButtonOpacity ? '0.9' : '1',
              borderRadius: '100%',
              padding: '2%',
              width: '35px',
              height: '35px',
              border: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '15px',
              left: '15px',
              zIndex: 999,
            }}
          >
            <img src={BigChevronLeft} sx={{ width: '20px', height: '20px' }} />
          </button>
        )}
        {modalHeader && (
          <Flex
            sx={{
              p: ['15px', '0'],
              zIndex: '2',
              justifyContent: 'space-between',
              alignItems: 'center',
              minHeight: ['67px', 'auto'],
              backgroundColor: 'white',
              ...modalHeaderStyles,
            }}
          >
            <h1 sx={{ margin: '0px', padding: '0px' }}>{modalHeader}</h1>
          </Flex>
        )}
        <div
          sx={{
            height: modalHeight || '90vh',
            maxHeight: '90vh',
            overflowY: 'auto',
            padding: '10px',
            ...modalContentStyles,
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  )
}
