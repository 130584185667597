/** @jsx jsx */
import React from 'react'
import { Box, Flex, jsx } from 'theme-ui'
import { graphql, navigate } from 'gatsby'
import { flatMap, startCase, uniqBy } from 'lodash'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Card from '../components/Card'
import CardList from '../components/CardList'
import Pagination from '../components/Pagination'
import Container from '../components/Container'
import Header from '../components/Header'
import { useLocalStorage } from 'react-use'
import Confetti from '../components/shared/Confetti'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { ShareButtons, SocialLinks } from '../components/shared/ShareButtons'
import { ModalButton } from '../components/shared/ModalButton'

const CourseTemplate = ({ data, pageContext }) => {
  const [quizes] = useLocalStorage('quizes', [])
  const posts = data.contentfulCourse.posts

  const {
    title,
    id,
    heroImage,
    unfinished,
    slug,
    badge,
  } = data.contentfulCourse

  const courseComplete = posts
    ?.filter((p) => p?.quizQuestions)
    .every((p) =>
      quizes.some(
        (q) => q.id === p.id && q.questionKey === p.quizQuestions.length
      )
    )

  let ogImage
  try {
    ogImage = heroImage?.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (
    <>
      <Layout>
        <SEO
          title={`Course: ${startCase(title)}`}
          description={`Posts Course: ${startCase(title)}`}
          image={ogImage}
        />
        <Header
          headerText={title}
          hideSearch
          image={heroImage?.gatsbyImageData}
          imageAltText={title}
          alert={
            courseComplete && {
              text: (
                <Flex
                  sx={{
                    flexDirection: 'column',
                    p: '5px',
                    gap: '10px',
                    minWidth: ['none', '400px'],
                    alignItems: 'center',
                  }}
                >
                  Cogratulations! You have completed this course.
                  <CertificateModal
                    courseName={title}
                    courseShareURL={location.href}
                    courseBadge={badge.file.url}
                  />
                </Flex>
              ),
            }
          }
        />

        <Container mw padded>
          {courseComplete && <Confetti style={{ position: 'fixed' }} />}
          By SafeBeachDay with help of
          <Flex
            sx={{
              flexWrap: 'wrap',
              gap: '30px',
              py: 20,
              pb: 40,
              alignItems: 'center',
            }}
          >
            {uniqBy(flatMap(posts.map((p) => p.authors)), 'id').map(
              (a, index) => (
                <Flex key={index} sx={{ alignItems: 'center', gap: '5px' }}>
                  {a.organizationLogo && (
                    <GatsbyImage
                      title={a.organizationName}
                      alt={a.organizationName}
                      image={a.organizationLogo.gatsbyImageData}
                    />
                  )}
                  <Box>
                    <h2 sx={{ fontSize: [1, null, 2], mb: 0 }}> {a.name}</h2>
                    {a.organizationName}
                  </Box>
                </Flex>
              )
            )}
          </Flex>
          <Box key={2}>
            <CardList listStyle={true} style={{ paddingTop: '120px' }} key={20}>
              <Flex
                sx={{
                  width: '80px',
                  height: '80px',
                  borderRadius: '50%',
                  position: 'absolute',
                  top: '-5px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: '5px',
                  textAlign: 'center',
                  margin: 'auto',
                  cursor: 'pointer',
                  bg: 'green',
                  color: 'white',
                }}
                onClick={() => {
                  navigate(
                    `/post/${posts[0].slug}?course=${id}&courseSlug=${slug}`
                  )
                }}
              >
                Start Here
              </Flex>

              {posts.map((post) => {
                const quiz = quizes.find((q) => q.id === post.id)

                const isComplete = quiz
                  ? quiz.questionKey + 1 > post.quizQuestions.length
                  : false

                return (
                  <Box key={post.id} sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        width: '15px',
                        position: 'absolute',
                        top: '-40px',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        height: '150px',
                        zIndex: -1,
                        margin: 'auto',
                        bg: 'gray',
                      }}
                    />
                    {isComplete && (
                      <Box
                        sx={{
                          width: '15px',
                          position: 'absolute',
                          top: '-40px',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          height: '150px',
                          zIndex: -1,
                          margin: 'auto',
                          bg: 'green',
                        }}
                      />
                    )}
                    <Card
                      {...post}
                      title={
                        <Flex
                          sx={{
                            alignItems: 'center',
                            gap: '10px',
                            width: '100%',

                            justifyContent: 'space-between',
                          }}
                        >
                          {post.title}{' '}
                          {isComplete && (
                            <h5 sx={{ color: 'green' }}>Complete</h5>
                          )}
                        </Flex>
                      }
                      key={post.id}
                      slug={`${post.slug}?course=${id}&courseSlug=${slug}`}
                      basePath={'post'}
                      listStyle
                    />
                  </Box>
                )
              })}
            </CardList>
          </Box>
          {unfinished && (
            <Flex
              sx={{
                textAlign: 'center',
                p: 30,
                width: '100%',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <h4>
                This course has more lessons to come... Check back or follow us
                on socials for updates
              </h4>
              <SocialLinks />
            </Flex>
          )}
        </Container>
        <Pagination context={pageContext} />
      </Layout>
    </>
  )
}

const CertificateModal = ({ courseName, courseBadge, courseShareURL }) => {
  return (
    <ModalButton
      buttonVariant={'white'}
      buttonText={'Download Your Certificate'}
      modalHeight={'auto'}
      modalWidth={400}
      renderModalContent={() => (
        <Flex
          sx={{
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'flex-start',
            px: 40,
          }}
        >
          <StaticImage
            placeholder={'none'}
            src={'../images/SafeBeachDayLogoTransparent.png'}
            title={'Safe Beach Day'}
            alt={'Safe Beach Day'}
            sx={{
              width: '150px',
              margin: 'auto',
            }}
          />
          <h1>Course Complete!!</h1>
          <img src={courseBadge} width={'170px'} />
          <h3 sx={{ mb: '0px' }}>You've Successfully Completed!</h3>
          <p sx={{ mb: '30px', mt: '5px' }}>
            {courseName} course on SafeBeachDay
          </p>
          Share your with your friends!
          <ShareButtons
            message={`I just completed the ${courseName} on Safe Beach Day!  Check it out and stay safe!`}
            url={courseShareURL}
          />
        </Flex>
      )}
    />
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulCourse(slug: { eq: $slug }) {
      title
      id
      slug
      unfinished
      heroImage: mainImage {
        title
        gatsbyImageData(width: 1800)
        ogimg: resize(width: 1800) {
          src
        }
      }
      badge {
        file {
          url
        }
      }
      posts {
        id
        title
        slug
        publishDate(formatString: "MMMM DD, YYYY")
        publishDateISO: publishDate(formatString: "YYYY-MM-DD")
        heroImage {
          title
          gatsbyImageData(width: 1800, layout: CONSTRAINED)
          ogimg: resize(width: 1800) {
            src
          }
        }
        body {
          childMarkdownRemark {
            timeToRead
            html
            excerpt(pruneLength: 80)
          }
        }
        quizQuestions {
          question
          answer
          options
        }
        authors {
          id
          name
          organizationName
          organizationLogo {
            gatsbyImageData(width: 60, height: 60)
          }
        }
      }
    }
  }
`

export default CourseTemplate
